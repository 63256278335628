import React from 'react'
import { graphql } from 'gatsby'
import { Accordion, Card } from 'react-bootstrap'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

// TAX CHECKLIST ACCORDION IMPORTS
import TaxVariation2020 from '../../assets/pdf/2020_WSC_Group_PAYGW_Tax_Variation_Check_List.pdf'
import TaxVariation2019 from '../../assets/pdf/PAYGW_Tax Variation_–_Year_ending_30_June_2019.pdf'
import IndividualTaxReturn2019 from '../../assets/pdf/WSC_Group_2019_Individual_Income_Tax_Return_Checklist.pdf'
import RentalTaxReturn2019 from '../../assets/pdf/WSC_Group_2019_Rental_Property_Tax_Checklist.pdf'
import SelfManaged2019 from '../../assets/pdf/WSC_Group_2019_Self-Managed_Superfund_Checklist.pdf'
import BusinessChecklist2019 from '../../assets/pdf/WSC_Group_2019_Self-Managed_Superfund_Checklist.pdf'
import TaxVariation2018 from '../../assets/pdf/2018_Tax_Variation_Checklist.v3.pdf'
import BusinessChecklist2018 from '../../assets/pdf/WSC_Group_2018_Business_Check_List.pdf'
import IncomeTaxReturn2018 from '../../assets/pdf/WSC_Group_2018_Individual_Income_Tax_Return_Check_List.pdf'
import ClientChecklist2018 from '../../assets/pdf/WSC_Group_2018_Self-Managed_Superfund_Check_List.pdf'
import RentalTaxReturn2018 from '../../assets/pdf/WSC_Group_2018_Rental_Property_Tax_Check_List.pdf'
import IncomeTaxReturn2017 from '../../assets/pdf/2017_WSC_Group_2017_Individual_Income_Tax_Return_Checklist.pdf'
import RentalProperty2017 from '../../assets/pdf/2017_WSC_Group_2017_Rental_Property_Tax_Checklist.pdf'
import BusinessChecklist2017 from '../../assets/pdf/2017_WSC_Group_2017_Business_Checklist.pdf'
import SelfManaged2017 from '../../assets/pdf/2017_WSC_Group_2017_Self-Managed_Superfund_Checklist.pdf'
import TaxVariation2016 from '../../assets/pdf/2017_PAYG_Withholding_Tax_Variation_Checklist.pdf'
import IndividualTaxReturn2016 from '../../assets/pdf/WSC_Group_2016_Individual_Income_Tax_Return_Checklist.pdf'
import RentalProperty2016 from '../../assets/pdf/WSC_Group_2016_Rental_Property_Tax_Checklist.pdf'
import BusinessChecklist2016 from '../../assets/pdf/WSC_Group_2016_Business_Checklist.pdf'
import SelfManaged2016 from '../../assets/pdf/WSC_Group_2016_Self-Managed_Superfund_Checklist.pdf'
import IndividualTaxReturn2015 from '../../assets/pdf/WSC_Group_2015_Individual_Income_Tax_Return_Checklist.pdf'
import RentalProperty2015 from '../../assets/pdf/WSC_Group_2015_Rental_Property_Tax_Checklist.pdf'
import BusinessChecklist2015 from '../../assets/pdf/WSC_Group_2015_Business_Checklist.pdf'
import SelfManaged2015 from '../../assets/pdf/WSC_Group_2015_Self-Managed_Superfund_Checklist.pdf'

class TaxChecklists extends React.Component {
	render() {
		const siteTitle = 'Tax Checklists'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 left-list">
							<div id="contentgen">
								<h3 id="checklist-header">Tax Checklists</h3>
								<Accordion defaultActiveKey="0">
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2020"
										>
											<i className="fa fa-calendar" /> 2020
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2020">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={TaxVariation2020}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2020 WSC Group PAYGW Tax Variation Check List
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2019"
										>
											<i className="fa fa-calendar" /> 2019
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2019">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={TaxVariation2019}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018/2019 PAYG Withholding Tax Variation Check
																	List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={IndividualTaxReturn2019}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2019 Individual Income Tax Return Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={RentalTaxReturn2019}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2019 Rental Property Income Tax Return
																	Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={SelfManaged2019}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2019 Self-Managed Superannuation Client
																	Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={BusinessChecklist2019}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2019 Business Checklist{' '}
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2018"
										>
											<i className="fa fa-calendar" /> 2018
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2018">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={TaxVariation2018}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018 PAYG Withholding Tax Variation Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={BusinessChecklist2018}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018 Business Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={IncomeTaxReturn2018}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018 Income Tax Return Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={ClientChecklist2018}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018 Self-Managed Superannuation Client
																	Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={RentalTaxReturn2018}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2018 Rental Property Income Tax Return
																	Checklist
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2017"
										>
											<i className="fa fa-calendar" /> 2017
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2017">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={IncomeTaxReturn2017}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2017 Individual Tax Return Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={RentalProperty2017}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2017 Rental Property Tax Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={BusinessChecklist2017}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2017 Business Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={SelfManaged2017}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2017 Self-Managed Superannuation Fund Check
																	List
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2016"
										>
											<i className="fa fa-calendar" /> 2016
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2016">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={TaxVariation2016}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2016/17 PAYG Withholding Tax Variation
																	Checklist
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={IndividualTaxReturn2016}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2016 Individual Tax Return
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={RentalProperty2016}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2016 Rental Property Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={BusinessChecklist2016}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2016 Business Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={SelfManaged2016}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2016 Self-Managed Superannuation Fund Check
																	List
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											variant="link"
											eventKey="2015"
										>
											<i className="fa fa-calendar" /> 2015
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2015">
											<Card.Body>
												<div className="container">
													<div className="row">
														<div className="col">
															<p className="checklist-content">
																<a
																	href={IndividualTaxReturn2015}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2015 Individual Income Tax Return Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={RentalProperty2015}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2015 Rental Property Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={BusinessChecklist2015}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2015 Business Check List
																</a>
															</p>
															<p className="checklist-content">
																<a
																	href={SelfManaged2015}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<span className="fa fa-download fa-lg">
																		&nbsp;
																	</span>
																	2015 Self-Managed Superannuation Fund Check
																	List
																</a>
															</p>
														</div>
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TaxChecklists

export const TaxChecklistsPageQuery = graphql`
	query TaxChecklistsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
